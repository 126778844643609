#loading-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: white;
    left: 0;
    top: 0;
    z-index: 1000;
}

#loading-content {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    margin: -60px 0 0 -60px;
    border: 5px solid var(--color-cyan-primary);
}

#loading-content:after {
    content: "";
    position: absolute;
    border: 5px solid var(--color-orange-primary);
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 15px;
}

#loading-content:before {
    content: "";
    position: absolute;
    border: 10px solid var(--color-cyan-primary);
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
}

#loading-content {
    border: 10px solid transparent;
    border-top-color: var(--color-blue-primary);
    border-bottom-color: var(--color-blue-primary);
    border-radius: 50%;
    -webkit-animation: loader 2s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 2s linear infinite;
}

#loading-content:before {
    border: 5px solid transparent;
    border-top-color: var(--color-orange-primary);
    border-bottom-color: var(--color-orange-primary);
    border-radius: 50%;
    -webkit-animation: loader 3s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 3s linear infinite;
}

#loading-content:after {
    border: 7px solid transparent;
    border-top-color: var(--color-blue-tertiary);
    border-bottom-color: var(--color-blue-tertiary);
    border-radius: 50%;
    -webkit-animation: loader 1.5s linear infinite;
    animation: loader 1.5s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
}

@-webkit-keyframes loaders {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

#content-wrapper {
    color: #FFF;
    position: fixed;
    left: 0;
    top: 20px;
    width: 100%;
    height: 100%;
}

#header {
    width: 800px;
    margin: 0 auto;
    text-align: center;
    height: 100px;
    background-color: #666;
}

#content {
    width: 800px;
    height: 1000px;
    margin: 0 auto;
    text-align: center;
    background-color: #888;
}